import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { LoggedInGuard } from "./core/guards/logged-in.guard";
import { ProfileGuard } from "./core/guards/profile.guard";
import { QuestionnaireAuthInterceptor } from "./profile/interceptors";
import {
  DiaryRecipesService,
  DiaryService,
  QuestionnaireService,
} from "./profile/services";
import { DiaryDetailsService } from "./profile/services/diary-details.service";
import { DiaryDietarySupplementService } from "./profile/services/diary-dietary-supplement.service";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "auth",
    canActivate: [LoggedInGuard],
    loadComponent: () =>
      import("@app/auth/auth.component").then((m) => m.AuthComponent),
    loadChildren: () => import("@app/auth/auth.routes").then((m) => m.routes),
  },
  {
    path: "dashboard",
    data: { page: "dashboard" },
    canActivate: [AuthGuard],
    loadComponent: () =>
      import("@app/core/pages/dashboard/dashboard.component").then(
        (m) => m.DashboardComponent,
      ),
    loadChildren: () =>
      import("@app/core/pages/dashboard/dashboard.routes").then(
        (m) => m.routes,
      ),
  },
  {
    path: "profile",
    data: { page: "profile" },
    canActivate: [ProfileGuard],
    loadComponent: () =>
      import("@app/profile/profile.component").then((m) => m.ProfileComponent),
    loadChildren: () =>
      import("@app/profile/profile.routes").then((m) => m.routes),
    providers: [
      DiaryService,
      QuestionnaireService,
      DiaryRecipesService,
      DiaryDietarySupplementService,
      DiaryDetailsService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: QuestionnaireAuthInterceptor,
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
    ],
  },
  // !Исключаем из загузки
  // {
  //   path: 'ui-kits',
  //   data: { page: 'ui-kits' },
  //   loadChildren: () =>
  //     import('@app/shared/components/ui-kits/ui-kits.module').then((m) => m.UiKitsModule),
  // },
  // TODO NOT FOUND Component 404
  {
    path: "**",
    redirectTo: "dashboard",
  },
];
