import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import * as fromProfile from "@app/profile/store";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest, of } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { EXCLUDED_PAGES } from "../consts/excluded-pages.conts";

@Injectable({
  providedIn: "root",
})
export class ProfileGuard {
  constructor(
    private store: Store<fromProfile.State>,
    private router: Router,
  ) {}

  excludedPages: string[] = EXCLUDED_PAGES;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // this.store.dispatch(fromProfile.checkPatientHash());
    this.store.dispatch(fromProfile.getProfileInfoFromStorage());

    for (let pageUrl of this.excludedPages) {
      if (state.url.includes(pageUrl)) {
        return of(true);
      }
    }

    return combineLatest([
      this.store.pipe(select(fromProfile.selectProfileInfo())),
      this.store.pipe(select(fromProfile.selectPatientId())),
      this.store.pipe(select(fromProfile.selectIsLoaded())),
    ]).pipe(
      filter(([profileInfo, patientId, isLoaded]) => isLoaded),
      map(([profileInfo, patientId, isLoaded]) => {
        if (profileInfo || patientId) {
          return true;
        } else {
          // this.router.navigate([`/profile/questions-greeting#${profileInfo.hash}`]);
          this.router.navigate(["/profile/questions-greeting"]);
          return false;
        }
      }),
      take(1),
    );
  }
}
