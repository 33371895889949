import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, mergeMap, take } from "rxjs";

import * as fromProfile from "@app/profile/store";
import { ALLOWED_API_PATHS_FOR_AUTH } from "../profile.consts";

@Injectable()
export class QuestionnaireAuthInterceptor implements HttpInterceptor {
  private readonly allowed_paths = ALLOWED_API_PATHS_FOR_AUTH;

  constructor(private store: Store<fromProfile.State>) {}

  private isAllowedRequest(request: HttpRequest<any>): boolean {
    return this.allowed_paths.some((path) => request.url.includes(path));
  }

  private getToken() {
    return this.store.pipe(select(fromProfile.selectAuthToken()), take(1));
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.getToken().pipe(
      mergeMap((token) => {
        if (this.isAllowedRequest(req) && token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(req);
      }),
    );
  }
}
