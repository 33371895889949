import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class CleanHttpParamsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let params = request.params;

    request.params.keys().forEach((key) => {
      if (params.get(key) === null) {
        params = params.delete(key);
      }
    });

    request = request.clone({ params });
    return next.handle(request);
  }
}
