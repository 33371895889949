import { Location } from "@angular/common";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as fromProfileSettings from "@app/profile/store/selectors";
import { HTTPStatusCode } from "@app/shared/enums/http-status-code";
import * as fromRoot from "@app/store";
import { Store, select } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { EXCLUDED_PAGES } from "../consts/excluded-pages.conts";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  excludedPages: string[] = EXCLUDED_PAGES;

  readonly QUESTIONNAIRE_API_PATH = "/api/patient/questionnaire";

  constructor(
    private store: Store<fromRoot.State>,
    private location: Location,
    private router: Router,
  ) {}

  isExcludedPage(page: string) {
    for (let pageUrl of this.excludedPages) {
      if (page.includes(pageUrl)) {
        return true;
      }
    }
    return false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const location = this.location.path();
    const requestUrl = request.url;
    if (
      this.isExcludedPage(location) &&
      requestUrl.includes(this.QUESTIONNAIRE_API_PATH)
    ) {
      return this.store.pipe(
        select(fromProfileSettings.selectAllSettings()),
        mergeMap((settings) => {
          const { hash_id, only } = settings;
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === HTTPStatusCode.UNAUTHORIZED) {
                const queryParams: any = {
                  hash_id,
                };
                if (only) {
                  queryParams["only"] = only;
                }

                this.router.navigate(["/profile/questions/secret-word"], {
                  queryParams,
                });
              }

              return throwError({
                ...error.error,
                status: error.status,
                statusText: error.statusText,
              });
            }),
          );
        }),
      );
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HTTPStatusCode.UNAUTHORIZED) {
          this.store.dispatch(fromRoot.logoutSuccess());
        }

        return throwError({
          ...error.error,
          status: error.status,
          statusText: error.statusText,
        });
      }),
    );
  }
}
